$(function () {
    $("#conf_select").select2({
        language: "en-US",
        placeholder: "Enter conference name, acronym or id",
        ajax: {
            url: "/api/search_conference",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return { q: params.term };
            },
            processResults: function (data) {
                return { results: data };
            },
            cache: true
        },
        minimumInputLength: 2,
        escapeMarkup: function (markup) { return markup; },
        /* language: {
            inputTooShort:function(n){
                return "最少输入"+(n.minimum-n.input.length)+"个字符"
            }
        }, */
    });
    $('#conf_select').change(function () {
        $('#conf_form').attr('action', '/ccp_' + $(this).val() + '/')
    });
    $("#organ_select").select2({
        language: "en-US",
        placeholder: "Enter institution name",
        ajax: {
            url: "/api/search_iv_institution",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return { q: params.term };
            },
            processResults: function (data) {
                if (data) {
                    for (const key in data) {
                        data[key].id = data[key].institution_id;
                    }
                }
                return { results: data };
            },
            cache: true
        },
        minimumInputLength: 2,
        escapeMarkup: function (markup) { return markup; },
    });
    $('#organ_select').change(function () {
        $('#conf_form').attr('action', 'iv/' + $(this).val())
    });
})